<template>
  <div class="Client font">
    <!-- BEGIN: Content-->
    <div class="app-content content">
      <div class="content-overlay"></div>
      <div class="header-navbar-shadow"></div>

      <div class="content-wrapper container-xxl p-0 font">
        <banner
          title="Service Charge"
          :breadcrumb="[
            {
              label: 'Dashboard',
            },
            { label: 'Services' },
          ]"
        >
        </banner>

        <div v-if="loading == true">
          <Spinner />
        </div>
        <div class="row font" style="margin-top: -45px">
          <div class="col-xl-12 col-md-12 col-sm-12">
            <div
              class="card custom-card"
           
            >
              <div class="card-body">
                <!-- start list area  -->

                <div class="row">
                  <div class="col text font">

                    <h4>
                        <strong style="color: #00364f"> Service Charges</strong>
                      </h4>
                  </div>
                  <div class="col-xl-1 col-md-1 col-3 text-end cus-p-1">
                    <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
                   

                  </div>
                   
                </div>




                <div class="row">
            

                  <div
                    class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12"
                 
                  >
                    <div class="container-fluid table-scroll">
                      <table class="table table-hover table-sm">
                        <tr class="text tr-head table-wrapper-scroll-y rounded-circle">
                          <th>+</th>
                          <th class="text-truncate">Name</th>
                          <!-- <th class="text-truncate">Service Charges</th> -->
                          <th class="text-truncate">Master Distributer&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <th class="text-truncate">Distributer&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <th class="text-truncate">Retailer&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</th>
                          <!-- <th class="text-end">Action</th> -->
                        </tr>

                        <tbody>
                          <tr
                            style="color: #00364f"
                            v-for="(admService, index) in adminServices"
                            :key="index"
                          >
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.service.code }}
                            </td>
                            <td class="text-truncate" v-if="admService.service">
                              {{ admService.service.name }}
                            </td>
                            <!-- <td class="text-truncate" v-if="admService">
                              {{ admService.serviceCharge }}
                            </td> -->

                            <td class="text-truncate">
                              <div class="input-group">
                                <input
                                  type="text"
                                  :value="admService.masterDistributerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>

                            <td>
                              <div class="input-group">
                                <input
                                  type="text"
                                  :value="admService.distributerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>

                            <td class="text-truncate">
                              <div class="input-group">
                                <input
                                  type="text"
                                  :value="admService.retailerFee"
                                  class="form-control"
                                  placeholder="Percentage"
                                  style="height: 30px"
                                  disabled
                                />
                                <span
                                  style="height: 30px"
                                  class="input-group-text"
                                  id="basic-addon1"
                                  ><strong>%</strong></span
                                >
                              </div>
                            </td>
                            <!-- <td> -->
                            <!-- <button
                                  type="button"
                                  @click="saveServiceChargesAdmin(admService)"
                                  class="btn btns text-white btn-sm"
                                  style="
                                    padding: 5px 4.5px 5px 4.5px;
                                    background-color: #00364f;
                                  "
                                >
                                  <font-awesome-icon icon="floppy-disk" />
                                </button> -->
                            <!-- <button
                                type="button"
                                @click="editServiceCharges(admService)"
                                data-bs-toggle="modal"
                                data-bs-target="#updatecharges"
                                class="btn btns text-white btn-sm"
                                style="
                                  padding: 5px 4.5px 5px 4.5px;
                                  background-color: green;
                                "
                              >
                                <font-awesome-icon icon="edit" />
                              </button>
                            </td> -->
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <!-- end list area -->
              </div>
          
              <div class="card-footer">
                    <div class="row">

                      <div class="col-md-4 col-12 text-md-start text-center cus-p-1">

                        <p class="mb-0">
                          Showing {{ pagination.from }} to {{ pagination.to }} of {{ pagination.total }} entries
                        </p>
                      </div>
                      <div class="col-md-8 col-12 text-md-end text-center cus-p-1">
                        <ul class="pagination  justify-content-center">
                          <li class="page-item" @click="getServices(null, 'first')">
                            <a class="page-link" href="#" aria-label="Previous">
                              <span aria-hidden="true">&laquo;</span>
                            </a>
                          </li>
                          <li class="page-item" @click="getServices(null, 'prev')">
                            <a class="page-link" href="#">Previous</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 3"
                            @click="getServices(pagination.current_page - 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 3
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 2"
                            @click="getServices(pagination.current_page - 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page > 1"
                            @click="getServices(pagination.current_page - 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page - 1
                            }}</a>
                          </li>
                          <li class="active page-item" @click="getServices(pagination.current_page)">
                            <a class="page-link" href="#">{{ pagination.current_page }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 4"
                            @click="getServices(pagination.current_page + 1)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 1
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 5"
                            @click="getServices(pagination.current_page + 2)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 2
                            }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 6"
                            @click="getServices(pagination.current_page + 3)">
                            <a class="page-link" href="#">{{
                              pagination.current_page + 3
                            }}</a>
                          </li>
                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.current_page + 4 )"><a href="#">{{pagination.current_page + 4}}</a></li> -->
                          <li class="page-item">
                            <a class="page-link" href="#">...</a>
                          </li>

                          <!-- <li v-if="pagination.current_page < (pagination.last_page - 4)" @click="getServices( pagination.last_page - 4 )"><a href="#">{{pagination.last_page - 4}}</a></li> -->
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 3"
                            @click="getServices(pagination.last_page - 3)">
                            <a class="page-link" href="#">{{ pagination.last_page - 3 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 2"
                            @click="getServices(pagination.last_page - 2)">
                            <a class="page-link" href="#">{{ pagination.last_page - 2 }}</a>
                          </li>
                          <li class="page-item" v-if="pagination.current_page < pagination.last_page - 1"
                            @click="getServices(pagination.last_page - 1)">
                            <a class="page-link" href="#">{{ pagination.last_page - 1 }}</a>
                          </li>

                          <li class="page-item" @click="getServices(null, 'next')">
                            <a class="page-link" href="#">Next</a>
                          </li>
                          <li class="page-item" @click="getServices(null, 'last')">
                            <a class="page-link" href="#">&raquo;</a>
                          </li>
                        </ul>
                      </div>

                      <!-- <div class="col-md-1 col-4 text-end">
      <select
                      style="height: 30px; padding: 5px 5px 5px 5px"
                      class="form-select"
                      @change="changePageNo"
                      aria-label="Default select example"
                    >
         
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                      <option value="100">100</option>
                    </select>
    </div> -->
                    </div>
                  </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Content-->

    <div class="sidenav-overlay"></div>
    <div class="drag-target"></div>
  </div>
</template>

//
<script>

import Spinner from "../../../components/master-distributer/comman/Spinner.vue";
import Banner from "../../../components/master-distributer/comman/Banner.vue";

// import Form from "vform";
import jQuery from "jquery";
let $ = jQuery;
export default {
  name: "MasetrDistributerAdminSerfvicesCharge",

  components: {
    Banner,
    Spinner,
  },
  data() {
    return {
      pagination: {
        current_page: 0,
        from: 0,
        to: 0,
        total: 0,
        last_page: 0,
        per_page: 0,
        first_page_url: null,
        last_page_url: null,
        next_page_url: null,
        prev_page_url: null,
        path: null,
      },
      per_page: 10,

      portal: "",
      msgError: "",
      loading: false,
      adminServices: [],
      edit: "false",
      admin_services_charge_form: {
        retailerFee: null,
        distributerFee: null,
        masterDistributerFee: null,
      },
    };
  },
  methods: {
    changePageNo(e) {
      this.per_page = e.target.value;
      this.getServices();
    },
    editServiceCharges(admService) {
      this.admin_services_charge_form = admService;
      this.msgError = "";
    },
    saveServiceChargesAdmin() {
      var val1 = this.admin_services_charge_form.masterDistributerFee;
      var val2 = this.admin_services_charge_form.distributerFee;
      var val3 = this.admin_services_charge_form.retailerFee;

      var res = parseInt(val1) + parseInt(val2) + parseInt(val3);
      // console.log(res);
      if (res <= 100) {
        this.$axios
          .put(
            `admin/adminservice/${this.admin_services_charge_form.id}`,
            this.admin_services_charge_form,
            {
              headers: { Authorization: "Bearer " + localStorage.accessToken },
            }
          )
          .then((res) => {
            console.log(res.data.data.data);
            this.adminServices = res.data.data.data;
            $("#updatecharges").modal("hide");
            this.getServices();
          });
      } else {
        this.msgError =
          "Sum Of Master Distributer %, Distributer % and Retailer % less then 100% Must ";
      }
    },
   
    getServices(page = null, pg = null) {
      console.log(page);
      console.log(pg);
      this.loading = true;
      var pageUrl = "";
      if (this.pagination.last_page_url && pg == "last") {
        pageUrl = pageUrl + this.pagination.last_page_url;
      } else if (this.pagination.first_page_url && pg == "first") {
        pageUrl = pageUrl + this.pagination.first_page_url;
      } else if (this.pagination.prev_page_url && pg == "prev") {
        pageUrl = pageUrl + this.pagination.prev_page_url;
      } else if (this.pagination.next_page_url && pg == "next") {
        pageUrl = pageUrl + this.pagination.next_page_url;
      }

      if (pg == null) {
        pageUrl += `masterdistributer/adminservice?per_page=${this.per_page}`;
      } else {
        pageUrl += `&per_page=${this.pagination.per_page}`;
      }
      if (this.table_search) {
        pageUrl += `&searchTerm=${this.table_search}`;
      }
      if (page != null) {
        pageUrl += `&page=${page}`;
      }

      this.$axios
        .get(pageUrl, {
          headers: { Authorization: "Bearer " + localStorage.accessToken },
        })
        .then((res) => {
          console.log(res);
          this.adminServices = res.data.data.data;
          this.pagination = res.data.data;
        })
        .finally(() => (this.loading = false));
    },
    loadPageTitle(){
      console.log('--------------------------');
        
        document.title =this.$store.state.masterDistributerPageTitles.service
    console.log(this.$store.state.masterDistributerPageTitles.service)
    console.log('--------------------------');
    }
  },
  created() {
    if (
      this.$route.meta.redirectIfLoggedIn == "true" &&
      localStorage.getItem("accessToken") == null
    ) {
      this.$router.push({ name: "Login" });
    } else {
      this.getServices();
      this.portal = localStorage.getItem("portal");
    }
    this.loadPageTitle()
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap");

.custom-card {
  border-radius: 10px;
  box-shadow: 0px 0px 5px 0px rgba(206, 205, 205, 0.555);
  background-color: white;
}
.h4-heading {
  color: red;
  font-size: 1.2vw;
}

.font-heading {
  font-family: "Poppins", sans-serif;
  font-size: 3rem;
  color: rgb(216, 209, 209);
}

.text {
  font-family: "Poppins", sans-serif;
  color: #00364f;
}

.font {
  font-family: "Poppins", sans-serif;
}

.btns {
  background-color: #00364f;
  font-size: 12px;
  padding: 8px 8px 8px 8px;
}

.radius {
  border-radius: 23px;
}

.form-check-input:checked {
  background-color: #f21300;
  border-color: #f21300;
}

.tr-head {
  font-size: 11pt;
  margin-left: 25px;
  border-radius: 26px;
  background-color: #cdcdcd;
}

.table-scroll {
  overflow-y: auto;
}

.page-item.active .page-link {
  z-index: 3;
  border-radius: 5rem;
  background-color: #00364f;
  color: #fff !important;
  font-weight: 600;
}




.cus-p-1 {
  padding: 5px 5px 5px 5px;
}

@media only screen and (min-width: 600px) {
  .justify-content-center {
    justify-content: end !important;
  }
}

.card-footer {
  padding: 10px 10px 0px 10px;
}
</style>
